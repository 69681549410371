//implant

.page_implant {
  .implant_cont {
    margin-bottom: 60px;
    .l-boxr {
      // text-align: center;
    }
    .cross_section {
      width: 660px;
    }
    .bridge_denture,.partial_denture,.implant_gums,.supporter_gums {
      width: 630px;
    }
    .implant_merit {
      width: 500px;
      .merit_lead {
        font-weight: bold;
        font-size: 16px;
        margin: 20px 0;
      }
    }
    .implant_step {
      width: 600px;
      position: relative;
      span {
        display: block;
        background: url(../img/implant/ico_step.png) no-repeat 0 0;
        color: #14178F;
        position: absolute;
        width: 28px;
        height: 32px;
        margin-left: -50px;
        margin-top: 5px;
        font-family: "メイリオ",
                     Meiryo,
                     "ヒラギノ角ゴ Pro W3",
                     "Hiragino Kaku Gothic Pro",
                     Osaka,
                     "ＭＳ Ｐゴシック",
                     "MS PGothic",
                     sans-serif;
        font-weight: bold;
        text-align: center;
        padding-top: 5px;
      }
    }
    .implant_price_list {
      th {
        background: #D8F3F8;
        padding: 0;
        font-weight: bold;
        text-align: center;
      }
      td {
        padding: 30px 0;
        text-align: center;
        &:last-child {
          font-weight: bold;
          color: #14178F;
        }
      }
      margin: 40px 0 0;
    }
  }
}

//pc
@media (min-width: 768px) {
  .implant_cont {
    .message_lead {
      margin-bottom: 40px;
    }
  }
}

//sp
@media (max-width: 767px) {
  .sp_tel {
    padding: 0 5px;
    margin: 0 0 20px;
  }
  .page_implant {
    .implant_cont {
      margin: 0 0 25px;
      font-size: 14px;
      .cross_section,
      .bridge_denture,
      .partial_denture,
      .implant_gums,
      .supporter_gums,
      .implant_step,
      .implant_merit {
        width: 100%;
      }
      .implant_step {
        width: 90%;
        margin: 0 auto;
        span {
          background: none;
          position: inherit;
          margin: 0;
          display: inline;
        }
      }
      .implant_price_list {
        margin: 20px 0 0;
      }
      .message_lead {
        font-size: 15px;
        font-weight: bold;
        margin: 0 0 8px;
      }
      .l-boxl,.l-boxr {
        img {
          display: block;
          width: 70%;
          margin: 0 auto 20px;
        }
      }
    }
    .bnr_area {
      margin-bottom: 20px;
    }
  }
}
