//orthodontics

.page_orthodontics {
  .bg_main {
    background: url(../img/common/bg_main_about.jpg);
  }
  .orthodontics_cont {
    margin-bottom: 60px;
    .txt_orthodontics {
      width: 700px;
    }
    .img_equipment_counter {
      margin: 50px 0 70px;
    }
    .case_box3 {
      margin-top: -60px;
    }
  }
  .txtblue {
    color: #0e2b8b;
    font-size: 16px;
  }
  .step_lead {
    margin: 40px 0 40px;
  }
  .orthodontics_step {
    width: 600px;
    &.step_description {
      margin-left: 40px;
    }
  }
  .step_img {
    padding-right: 40px;
  }
  .orthodontics_step.txtblue {
    color: #0e2b8b;
    font-weight: bold;
    span {
      display: inline-block;
      background: url(../img/implant/ico_step.png) no-repeat 0 0;
      color: #14178F;
      width: 28px;
      height: 32px;
      font-family: "メイリオ",
                   Meiryo,
                   "ヒラギノ角ゴ Pro W3",
                   "Hiragino Kaku Gothic Pro",
                   Osaka,
                   "ＭＳ Ｐゴシック",
                   "MS PGothic",
                   sans-serif;
      font-weight: bold;
      text-align: center;
      padding-top: 5px;
      margin-right: 10px;
    }
  }
  .btn_perfectperio {
    img {
      display: block;
      width: 600px;
      margin: 0 auto;
      border: 5px solid #E4EBF6;
    }
  }
  .orthodontics_list {
    th {
      width: 20%;
      background: #D8F3F8;
      padding: 0 30px;
      font-weight: bold;
      text-align: left;
    }
    td {
      width: 20%;
      padding: 30px 20px;
      text-align: center;
      &.price {
        font-weight: bold;
        color: #14178F;
      }
      &.alignleft {
        text-align: left;
      }
    }
    margin: 40px 0 0;
  }
}

//pc
@media (min-width: 768px) {
  .orthodontics_cont {
    .message_lead {
      margin-bottom: 40px;
    }
  }
}

//sp
@media (max-width: 767px) {
  .sp_tel {
    padding: 0 5px;
    margin: 0 0 20px;
  }
  .page_orthodontics {
    .orthodontics_cont {
      margin: 0 0 25px;
      font-size: 14px;
      .l-boxl,.l-boxr {
        img {
          display: block;
          width: 70%;
          margin: 0 auto 20px;
        }
      }
      .step_img {
        padding-right: 0px;
      }
      .step_lead {
        margin: 20px 0 0px;
      }
      .orthodontics_step {
        width: 100%;
        &.step_description {
          width: 100%;
          padding-right: 70px;
        }
      }
      .case_box3 {
        margin-top: 0px;
        .l-boxl,.l-boxr {
          img {
            display: block;
            width: 100%;
            margin: 0 auto;
          }
        }
        .l-boxl {
          margin: 0 auto 20px;
        }
      }
      .btn_perfectperio {
        img {
          width: 100%;
          border-radius: 10px;
        }
      }
      .orthodontics_list {
        margin-top: 20px;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
      }
    }
  }
}
