//aesthetic

.page_aesthetic {
  .bg_main {
    background: url(../img/common/bg_main_aesthetic.jpg);
    background-position: center;
    background-repeat: no-repeat;
  }
  .aesthetic_cont {
    margin-bottom: 60px;
    .txt_aesthetic {
      width: 700px;
    }
    .img_equipment_counter {
      margin: 50px 0 70px;
    }
    .case_box3 {
      margin-top: -60px;
    }
  }
  .textblue {
    color: #0e2b8b;
    font-size: 16px;
  }
  .step_lead {
    margin: 40px 0 40px;
  }
  .step_img {
    padding-right: 40px;
  }
  .case_wrap {
    padding-top: 30px;
    .case_box {
      min-height: 0;
    }
    .case_box p {
      width: 180px;
    }
    .arow_inside {
      margin: 40px 20px 0;
    }
  }
  .point_wrap {
    border: 5px solid #D8F3F8;
    padding: 20px;
    overflow: hidden;
    margin-top: 20px;
    p {
      padding: 10px 20px 0;
      float: none;
      clear: both;
    }
    .point {
      background: #D8F3F8;
      border-radius: 10px;
      width: 270px;
      float: left;
      padding: 10px 5px;
      min-height: 180px;
      margin: 15px 15px;
      .txtblue {
        color: #0e2b8b;
        font-weight: bold;
        margin: 10px 0;
        span {
          display: inline-block;
          background: url(../img/aesthetic/ico_point.png) no-repeat 0 0;
          color: #14178F;
          width: 28px;
          height: 32px;
          font-family: "メイリオ",
                       Meiryo,
                       "ヒラギノ角ゴ Pro W3",
                       "Hiragino Kaku Gothic Pro",
                       Osaka,
                       "ＭＳ Ｐゴシック",
                       "MS PGothic",
                       sans-serif;
          font-weight: bold;
          text-align: center;
          padding-top: 5px;
          margin-right: 10px;
        }
      }
    }
  }
  .btn_perfectperio {
    img {
      display: block;
      width: 600px;
      margin: 0 auto;
      border: 5px solid #E4EBF6;
    }
  }
  .aesthetic_list {
    th {
      width: 34%;
      background: #D8F3F8;
      padding: 0 30px;
      font-weight: bold;
      text-align: left;
      vertical-align: middle;
    }
    td {
      font-weight: bold;
      width: 22%;
      padding: 30px 20px;
      text-align: center;
      &.price {
        color: #14178F;
      }
      &.alignleft {
        text-align: left;
      }
      &.bggray {
        background: #EEEEEE;
      }
    }
    margin: 40px 0 0;
  }
}

//pc
@media (min-width: 768px) {
  .aesthetic_cont {
    .message_lead {
      margin-bottom: 40px;
    }
    .case_box4 {
      margin-top: -50px;
    }
  }
}

//sp
@media (max-width: 767px) {
  .sp_tel {
    padding: 0 5px;
    margin: 0 0 20px;
  }
  .page_aesthetic {
    .aesthetic_cont {
      margin: 0 0 25px;
      font-size: 14px;
      .l-boxl,.l-boxr {
        img {
          display: block;
          width: 70%;
          margin: 0 auto 20px;
        }
      }
      .step_img {
        padding-right: 0px;
      }
      .point_wrap {
        padding: 0 10px;
        p:nth-child(1) {
          padding: 10px 0px 0;
        }
        .txtblue {
          padding: 0px 10px 0 !important;
        }
      }
      .point {
        width: 100%;
        min-height: 0px;
        float: none;
        margin: 20px auto;
        p {
          padding: 10px 10px 0;
        }
      }
      .case_box3 {
        margin-top: 0px;
        width: 100%;
        padding: 20px 5%;
        .l-boxl,.l-boxr {
          width: 100%;
          img {
            display: block;
            width: 100%;
            margin: 0 auto;
          }
        }
        .l-boxl {
          margin: 0 auto 20px;
        }
      }
      .case_wrap {
        padding: 0px 0 10px;
        .case_box {
          min-height: 0;
        }
        .case_box p {
          width: 100%;
          img {
            width: 100%;
            border-radius: 5px;
          }
        }
        .arow_inside {
          margin: 10px auto;
        }
      }
      .btn_perfectperio {
        img {
          width: 100%;
          border-radius: 10px;
        }
      }
      .aesthetic_list {
        margin-top: 20px;
      }
    }
  }
}
