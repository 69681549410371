//comingsoon

.page_comingsoon {
  .bg_main {
    background: url(../img/common/bg_main_about.jpg);
  }
  .comingsoon_cont {
    margin-bottom: 60px;
  }
  .txtblue {
    color: #0e2b8b;
    font-size: 16px;
    font-weight: bold;
  }
  .cont_s {
    padding: 0;
  }
  .cont_m {
    a {
      text-decoration: underline;
    }
    ul li {
      list-style: disc;
      margin-left: 20px;
    }
  }
}

//pc
@media (min-width: 768px) {
  .comingsoon_cont {
    .message_lead {
      margin-bottom: 40px;
    }
  }
}

//sp
@media (max-width: 767px) {
  .sp_tel {
    padding: 0 5px;
    margin: 0 0 20px;
  }
  .page_comingsoon {
    .comingsoon_cont {
      margin: 0 0 25px;
      font-size: 14px;
    }
  }
}
