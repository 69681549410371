@charset "UTF-8";

// @import "compass";

// partial import
@import "_bootstrap.scss";
@import "_mixin.scss";
@import "_base.scss";
@import "_layout.scss";
@import "_slick.scss";
@import "_index.scss";
@import "_implant.scss";
@import "_contact.scss";
@import "_equipment.scss";
@import "_rootcanal.scss";
@import "_periodontitis.scss";
@import "_orthodontics.scss";
@import "_aesthetic.scss";
@import "_treatment.scss";
@import "_about.scss";
@import "_privacy.scss";
@import "_partial.scss";
@import "_pagenotfound.scss";
@import "_comingsoon.scss";
@import "_module.scss";
