//treatment

.page_treatment {
  .bg_main {
    background: url(../img/common/bg_main_about.jpg);
  }
  .treatment_cont {
    margin-bottom: 60px;
  }
  .treatment_menu {
    margin-bottom: 30px;
    a {
      border-radius: 5px;
      background: #3b56ac;
      box-shadow: 0px 4px 0px 0px rgba(14, 43, 139, 1);
      color: #fff;
      text-align: center;
      z-index: 68;
      display: block;
      margin: 0 0 20px;
      font-size: 16px;
      font-weight: bold;
      @include noHover;
      @media (min-width: 768px) {
        @include hoverAction;
      }
      &:hover {
        text-decoration: none;
      }
      img {
        display: inline-block;
        vertical-align: middle;
        margin: 0 7px 0 0;
        padding: 11px 0 9px;
      }
      .txt {
        margin: 4px 0 0;
        display: inline-block;;
        vertical-align: middle;
        width: 100px;
        text-align: center;
      }
    }
  }
  .textblue {
    color: #0e2b8b;
    font-size: 16px;
  }
  .landing {
    width: 960px;
    height: 250px;
    margin-bottom: 40px;
    // &:nth-child(2) {
    //   background: url(../img/treatment/bg_landing01.jpg) no-repeat 0 0;
    // }
    // &:nth-child(3) {
    //   background: url(../img/treatment/bg_landing02.jpg) no-repeat 0 0;
    // }
    // &:nth-child(4) {
    //   background: url(../img/treatment/bg_landing03.jpg) no-repeat 0 0;
    // }
    // &:nth-child(5) {
    //   background: url(../img/treatment/bg_landing04.jpg) no-repeat 0 0;
    // }
    // &:nth-child(6) {
    //   background: url(../img/treatment/bg_landing05.jpg) no-repeat 0 0;
    // }
    // &:nth-child(7) {
    //   background: url(../img/treatment/bg_landing06.jpg) no-repeat 0 0;
    // }
    .ttl_landing {
      padding: 30px 40px 0;
    }
    .description {
      width: 490px;
      min-height: 110px;
      margin-left: 40px;
    }
    .linkdetail {
      margin-left: 40px;
      font-weight: bold;
      text-decoration: underline;
      img {
        text-decoration: none;
        margin-right: 20px;
      }
    }
  }
}

//pc
@media (min-width: 768px) {
  .treatment_cont {
    .message_lead {
      margin-bottom: 40px;
    }
    .landing {
      &:nth-child(2) {
        background: url(../img/treatment/bg_landing01.jpg) no-repeat 0 0;
      }
      &:nth-child(3) {
        background: url(../img/treatment/bg_landing02.jpg) no-repeat 0 0;
      }
      &:nth-child(4) {
        background: url(../img/treatment/bg_landing03.jpg) no-repeat 0 0;
      }
      &:nth-child(5) {
        background: url(../img/treatment/bg_landing04.jpg) no-repeat 0 0;
      }
      &:nth-child(6) {
        background: url(../img/treatment/bg_landing05.jpg) no-repeat 0 0;
      }
      &:nth-child(7) {
        background: url(../img/treatment/bg_landing06.jpg) no-repeat 0 0;
      }
    }
  }
}

//sp
@media (max-width: 767px) {
  .sp_tel {
    padding: 0 5px;
    margin: 0 0 20px;
  }
  .page_treatment {
    .treatment_menu {
      text-align: center;
      li {
        padding: 0 5px;
      }
      a {
        text-align: center;
        font-size: 14px;
        margin: 0 0 13px;
        img {
          margin:0;
        }
        .txt {
          margin-top: -4px;
          display: block;
          width: auto;
        }
      }
    }
    .treatment_cont {
      margin: 0 0 25px;
      font-size: 14px;
    }
    .landing {
      width: 100%;
      height: auto;
      margin-bottom: 20px;
      background: #EAEDF8;
      border-radius: 10px;
      .ttl_landing {
        padding: 30px 0 20px;
        width: 90%;
        margin: 0 auto;
      }
      .description {
        width: 90%;
        min-height: 0px;
        margin: 0 auto;
      }
      .linkdetail {
        width: 90%;
        margin: 0 auto;
        padding: 20px 0 30px;
        img {
          margin-right: 20px;
        }
      }
    }
  }
}
