//about

.page_about {
  .breadcrumb {
    width: $baseMinWidth;
    margin: 0 auto;
  }
  .bg_main {
    background: url(../img/common/bg_main_about.jpg);
  }
  .container {
    padding: 0;
    @media (min-width: 992px) {
      width: 100%;
    }
  }
  .about_cont_wrap {
    width: 100%;
    padding: 0;
    margin-bottom: 60px;
    .about_cont {
      width: $baseMinWidth;
      margin: 0 auto 60px;
    }
  }
  .about_cont_wrap.bgbase {
    background: $baseColor;
    padding: 50px 0 0px;
  }
  .about_cont {
    margin-bottom: 60px;
    position: relative;
  }
  .txt_introduce {
    width: 620px;
  }
  .txtblue {
    color: #0e2b8b;
    font-size: 20px;
  }
  .txtbold {
    font-weight: bold;
  }
  .headding_l {
    margin: 0;
  }
}

//pc
@media (min-width: 768px) {
  .page_about {
    a[id] {
      position: absolute;
      margin-top: -230px;
      margin-bottom: 230px;
    }
    .about_menu_sp {
      display: none;
    }
    .about_menu {
      background: url(../img/about/bg_about_menu.png) repeat-x 0 bottom;
      padding: 30px 0 30px;
      margin-bottom: 60px;
      .about_menu_inner {
        width: 800px;
        overflow: hidden;
        position: relative;
        margin: 0 auto;
        a {
          display: block;
          top: 0;
        }
        .about_menu01 {
          left: 0;
          position: absolute;
        }
        .about_menu02 {
          width: 89px;
          margin: 0 auto;
        }
        .about_menu03 {
          right: 0;
          position: absolute;
        }
      }
    }
    .about_cont {
      .flowimg img {
        margin-top: 50px;
      }
      .message {
        width: 295px;
        font-size: 0.9em;
      }
    }
    .last_contents {
      width: 960px !important;
      margin: 0 auto 60px;
    }
  }
}

//sp
@media (max-width: 767px) {
  .about_cont_wrap {
    padding-top: 30px !important;
    margin-bottom: 30px !important;
    .about_cont {
      width: 90% !important;
      .l-boxr,.l-boxl {
        img {
          display: block;
          margin: 0 auto 20px;
          width: 80%;
        }
      }
      margin-bottom: 30px !important;
    }
  }
  .about_cont_wrap.bgbase {
    margin: 0;
    padding: 40px 0 0;
  }
  .last_contents {
    width: 100%;
    .container {
      padding-left: 15px;
      padding-right: 15px;
      overflow: hidden;
    }
  }
  .sp_tel {
    padding: 0 5px;
    margin: 0 0 20px;
  }
  .page_about {
    .about_menu {
      display: none;
    }
    .about_menu_sp {
      display: block;
      width: 97%;
      overflow: hidden;
      position: relative;
      margin: 0 auto;
      a {
        display: block;
        color: #FFFFFF;
        top: 0;
        border-radius: 6px;
        width: 32%;
        text-align: center;
        padding: 10px 0;
        background: #3b56ac;
        font-weight: bold;
        font-size: 11px;
        text-decoration: none;
        @include noHover;
        @media (min-width: 768px) {
          @include hoverAction;
        }
        letter-spacing: -0.05em;
        &:hover {
        }
      }
      .about_menu01 {
        left: 0;
        position: absolute;
      }
      .about_menu02 {
        margin: 0 auto;
      }
      .about_menu03 {
        right: 0;
        position: absolute;
      }
    }
    .about_cont {
      margin: 0 0 25px;
      font-size: 14px;
    }
    .txt_introduce {
      width: 100%;
    }
    .landing {
      width: 100%;
      height: auto;
      margin-bottom: 20px;
      background: #EAEDF8;
      border-radius: 10px;
      .ttl_landing {
        padding: 30px 0 20px;
        width: 90%;
        margin: 0 auto;
      }
      .description {
        width: 90%;
        min-height: 0px;
        margin: 0 auto;
      }
      .linkdetail {
        width: 90%;
        margin: 0 auto;
        padding: 20px 0 30px;
        img {
          margin-right: 20px;
        }
      }
    }
  }
}
