//base-style
body
{
	background: $bgColor;
	font-family: $baseFontFamily;
	font-size: 14px;
	line-height: $baseLineHeight;
	color: $baseFontColor;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}

@media (max-width: 767px) {
	body {
		background: $bgColor;
		font-family: $baseFontFamily;
		font-size: 13px;
		line-height: $baseLineHeight;
		min-width: 320px;
		color: $baseFontColor;
		-webkit-text-size-adjust: 100%;
		-ms-text-size-adjust: 100%;
		.l-boxl,.l-boxr {
			float: none;
		}
		.iw100 {
			width: 100%;
		}
		p {
			margin: 0 0 15px;
		}
	}
}

a {
	color: $baseLinkColor;
	text-decoration: none;
	&:hover {
		color: $baseLinkColor;
		text-decoration: $baseHoverDecoration;
	}
}

table {
	width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
	th,td {
		border: $baseBorder;
		padding: $tablePadding;
	}
}

img {
  vertical-align: top;
}

.sp_item {
	display: none;
}

//-----------------------------------------------------------------------------------------

.op0{
	@include opacity(0);
}

.disN {
	display: none;
}


//-----------------------------------------------------------------------------------------

.l-box {
	overflow: hidden;
}

.l-boxl {
	float: left;
}

.l-boxr {
	float: right;
}

.l-boxcl {
	clear: both;
}

.l-list {
	overflow: hidden;
	> li {
		float: left;
	}
}

.l-iblist {
	@include noPadding;
	>li {
		display: inline-block;
	}
}

.l-btn {
	text-align: center;
	@media (min-width: 768px) {
		@media (min-width: 768px) {
			@include hoverAction;
		}
	}
}

.l-lbtn {
	text-align: left;
	@media (min-width: 768px) {
		@include hoverAction;
	}
}

.l-rbtn {
	text-align: right;
	@media (min-width: 768px) {
		@include hoverAction;
	}
}

.l-btn_list {
	text-align: center;
	> li {
		display: inline-block;
		margin: 0 30px 0 0;
		@media (min-width: 768px) {
			@include hoverAction;
		}
	}
}

a img:hover {
	@media (min-width: 768px) {
		@include hoverAction;
	}
}

p {
	margin: 0 0 $baseMargin;
}

.fb {
	font-weight: bold;
}

strong {
	font-weight: bold;
}

//-----------------------------------------------------------------------------------------

//-----------------------------------------------------------------------------------------

.f_cap {
	color: $captionFontColor;
}


@media (min-width: 768px){
	body {
		min-width: 990px;
	}
	.container {
		width: 990px;
	}
}

@media (min-width: 992px){
	body {
		min-width: 990px;
	}
	.container {
		width: 990px;
	}
}
