//partial

.page_partial {
  .bg_main {
    background: url(../img/common/bg_main_partial.jpg);
  }
  .partial_cont {
    margin-bottom: 60px;
    .partial_price_list {
      th {
        background: #D8F3F8;
        padding: 0;
        font-weight: bold;
        text-align: center;
        width: 20%;
      }
      td {
        padding: 10px 0;
        text-align: center;
        width: 40%;
        &:last-child {
          font-weight: bold;
          color: #14178F;
        }
        &.bggray {
          background: #EEEEEE;
          color: #000000;
          padding: 20px 0;
        }
      }
      margin: 40px 0 0;
    }
    .allpartial {
      width: 480px;
    }
    .partial_case {
      width: 400px;
    }
    .case_lead {
      margin-top: 30px;
    }
    .point_wrap {
      border: 5px solid #D8F3F8;
      padding: 20px;
      width: 100%;
      overflow: hidden;
      margin-top: 20px;
      p {
        padding: 10px 20px 0;
        float: none;
        clear: both;
      }
      .txtblue_ttl {
        color: #0e2b8b;
        font-weight: bold;
        margin: 0 -15px 20px;
        font-size: 16px;
      }
      .point {
        background: #D8F3F8;
        border-radius: 10px;
        width: 100%;
        padding: 10px 5px;
        min-height: 180px;
        margin: 15px auto;
        .txtblue {
          color: #0e2b8b;
          font-weight: bold;
          margin: 10px 0;
          span {
            display: inline-block;
            background: url(../img/aesthetic/ico_point.png) no-repeat 0 0;
            color: #14178F;
            width: 28px;
            height: 32px;
            font-family: "メイリオ",
                         Meiryo,
                         "ヒラギノ角ゴ Pro W3",
                         "Hiragino Kaku Gothic Pro",
                         Osaka,
                         "ＭＳ Ｐゴシック",
                         "MS PGothic",
                         sans-serif;
            font-weight: bold;
            text-align: center;
            padding-top: 5px;
            margin-right: 10px;
          }
        }
      }
    }
    .aligncenter {
      img {
        width: 630px;
        display: block;
        margin: 0 auto;
      }
    }
  }
}

//pc
@media (min-width: 768px) {
  .partial_cont {
    .message_lead {
      margin-bottom: 40px;
    }
    .photo_s {
      margin-top: 70px;
    }
  }
}

//sp
@media (max-width: 767px) {
  .sp_tel {
    padding: 0 5px;
    margin: 0 0 20px;
  }
  .page_partial {
    .partial_cont {
      margin: 0 0 25px;
      font-size: 14px;
      .partial_price_list {
        margin: 20px 0 0;
      }
      .l-boxl,.l-boxr {
        img {
          display: block;
          width: 100%;
          border-radius: 5px;
          margin: 0 auto 20px;
        }
      }
      .allpartial {
        width: 100%;
      }
      .aligncenter {
        img {
          width: 100%;
        }
      }
    }
  }
}
