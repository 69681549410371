//contact

.page_contact {
  .bg_main {
    background: url(../img/common/bg_main_about.jpg);
  }
  .container {
    padding: 0;
    .contact_cont_wrap {
      width: 100%;
      background: $baseColor;
      padding: 60px 0 1px;
      margin-bottom: 60px;
      .contact_cont {
        width: $baseMinWidth;
        margin: 0 auto 60px;
      }
    }
    .breadcrumb {
      @include defaultWidth;
    }
    @media (min-width: 992px) {
      width: 100%;
    }
  }
  .bnr_area_wrap {
    display: none;
  }
}

//pc
@media (min-width: 768px) {
  .page_contact {
    .last_contents {
      width: 960px !important;
      margin: 0 auto 60px;
    }
  }
}

//sp
@media (max-width: 767px) {
  .sp_tel {
    padding: 0 5px;
    margin: 0 0 20px;
  }
  .page_contact {
    .contact_cont_wrap {
      padding-top: 5px !important;
      margin-bottom: 10px !important;
      .contact_cont {
        width: 90% !important;
        .l-boxr,.l-boxl {
          img {
            width: 100%;
          }
        }
        margin-bottom: 5px !important;
      }
    }
    .last_contents {
      width: 100%;
      .container {
        padding-left: 15px;
        padding-right: 15px;
        overflow: hidden;
      }
    }
  }
}
