//------------# header #------------
header {
  .header_wrapper {
    background: $baseColor;
    .header_headding {
      color: #666;
      padding: 0;
      font-size: 12px;
    }
    .header_tel {
      a {
        @include noHover;
      }
    }
    .tel_caption {
      margin: 10px 0 12px;
      font-size: 14px;
      line-height: 1.4;
      strong {
        color: $accentColor;
      }
    }
  }
}

.cont_s {
  padding: 40px 0px 40px 0px;
  border-bottom: 1px dashed #9c9c9c;
}
.cont_m {
  padding: 40px 0px 40px 0px;
}

.case_wrap {
  padding: 60px 0 40px 0;
  .case_box {
    float: left;
    background: #EEEEEE;
    border-radius: 10px;
    padding: 30px;
    min-height: 370px;
    margin: auto;
    p {
      width: 220px;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .txtcenter {
      width: 100%;
      text-align: center;
      padding-top: 20px;
      float: none;
      clear: both;
    }
    .txtleft {
      width: 100%;
      padding-top: 20px;
      float: none;
      clear: both;
    }
    .txtleft:first-child {
      padding-top: 0;
    }
    .message_lead {
      text-align: center;
      font-weight: bold;
    }
    .l-boxcl p {
      width: 540px;
      padding: 20px 0 0;
    }
  }
  .case_box2 {
    width: 610px;
    margin: 0 auto;
    float: none;
  }
  .case_box3 {
    width: 700px;
    margin: 0 auto;
    float: none;
    min-height: 0;
    .l-boxl,.l-boxr {
      width: 200px;
    }
    .l-boxl {
      margin-right: 20px;
    }
  }
  .colmn4 {
    width: 940px;
    &.l-boxl:last-child {
      margin-right: 0;
    }
  }
  .case_box4 {
    width: 730px;
    margin: 0 auto;
    float: none;
  }
  .arrow {
    float: left;
    width: 25px;
    height: 41px;
    margin: 200px 16px 0;
    background: url(../img/rootcanal/arrow_case.png) no-repeat 0 0;
    @include textIn;
  }
  .arow_inside {
    margin: 150px 40px 0;
  }
}

//pc
@media (min-width: 768px) {
  header {
    width: 100%;
    position: fixed;
    z-index: 100;
  }
  .navi_about {
    cursor: pointer;
  }
  .pulldown_nav {
    position: absolute;
    width: 200px;
    margin-top: 17px;
    box-shadow: 0px 2px 2px 0px rgba(16,6,15,0.1);
    li {
      float: none !important;
      padding: 0 !important;
      background: rgba(255,255,255,0.8);
      border-top: 1px solid #ffffff;
      &:first-child {
        border-top: none;
      }
      a {
        text-indent: 0 !important;
        text-align: center;
        padding: 18px 10px;
        color: #000000;
        &:hover {
          text-decoration: none;
        }
      }
    }
    &.pullnav_treatment {
      margin-left: -0px;
    }
    &.pullnav_about {
      margin-left: -0px;
    }
  }
}

//sp
@media (max-width: 767px) {
  .table-responsive {
    table {
      border: 2px solid #cccccc;
      border-radius: 4px !important;
    }
    border: none !important;
    font-size: 10px !important;
    th {
      vertical-align: middle !important;
    }
    th,td {
      padding: 3px 3px !important;
    }
  }
  header {
    position: relative;
    .header_wrapper {
      padding: 9px 0 0;
      .container {
        padding: 40px 0 0;
      }
      .header_headding {
        font-size: 10px;
      }
      .headding_logo {
        padding: 8px 20px 8px 15px;
        position: fixed;
        top: 0;
        border-bottom: 1px solid #ccc;
        margin: 0;
        background: #DDF2F7;
        width: 100%;
        z-index: 1000;
        img {
          width: 240px;
        }
      }
      .tel_caption {
        padding: 0 0 0 15px;
        font-size: 11px;
        line-height: 1.3;
      }
    }
  }
  .cont_s {
    padding: 20px 0px 10px 0px;
  }
  .cont_m {
    padding: 20px 0px 10px 0px;
  }
  .case_wrap {
    padding: 20px 0 10px;
    width: 100%;
    .case_box {
      float: none;
      margin: auto;
      width: 100%;
      p {
        width: 100%;
        margin: 0 auto;
        img {
          width: 100%;
          border-radius: 5px;
        }
      }
      .message_lead {
        margin: 20px auto;
        font-size: 1.2em;
      }
      .l-boxcl p {
        width: 100%;
        padding: 20px 0 0;
      }
    }
    .case_box3 {
      margin: auto;
      width: 90%;
      .l-boxl,.l-boxr {
        width: 90%;
        margin: 0 auto;
        border-radius: 5px;
        overflow: hidden;
        &:last-child {
          margin: 0 auto;
        }
      }
    }
    .txtleft {
      text-align: center;
    }
    .arrow {
      float: none;
      width: 41px;
      height: 25px;
      margin: 10px auto;
      background: url(../img/rootcanal/arrow_case_sp.png) no-repeat 0 0;
      @include textIn;
    }
    .arow_inside {
      margin-bottom: 20px;
    }
  }
}
.pulldown_nav li {
  display: none;
}

//------------# nav #------------

@media (min-width: 768px) {
  .g_navi {
    background-color: rgba(255,255,255,.9);
    box-shadow: 0px 2px 2px 0px rgba(16, 6, 15, 0.1);
    display: block!important;
    ul {
      overflow: hidden;
      margin-bottom: 0px;
      li {
        padding: 25px 0 17px;
        float: left;
        a {
          @include textIn;
          &:before {
            height: 2px;
            background: $subColor;
          }
          $gNaviMargin: 20px;
          &.navi_home {
            background: url(../img/common/navi_home.png) no-repeat;
            // width: 41px;
            width: 41px;
            margin-left: 61px;
            margin-right: 50px + $gNaviMargin;
          }
          &.navi_treatment {
            background: url(../img/common/navi_treatment.png) no-repeat;
            // width: 55px;
            width: 55px;
            margin-left: 45px + $gNaviMargin;
            margin-right: 45px + $gNaviMargin;
          }
          &.navi_about {
            background: url(../img/common/navi_about.png) no-repeat;
            // width: 83px;
            width: 83px;
            margin-left: 40px + $gNaviMargin;
            margin-right: 40px + $gNaviMargin;
          }
          &.navi_case {
            background: url(../img/common/navi_case.png) no-repeat;
            // width: 70px;
            width: 70px;
            margin-left: 59px + $gNaviMargin;
            margin-right: 59px + $gNaviMargin;
          }
          &.navi_blog {
            background: url(../img/common/navi_blog.png) no-repeat;
            // width: 42px;
            width: 42px;
            margin-left: 47px + $gNaviMargin;
            margin-right: 47px + $gNaviMargin;
          }
          &.navi_contact {
            background: url(../img/common/navi_contact.png) no-repeat;
            // width: 82px;
            margin-left: 47px + $gNaviMargin;
            width: 82px;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .g_navi {
    display: none;
    background: $subColor;
    position: fixed;
    width: 100%;
    top: 50px;
    z-index: 1000;
    .container {
      padding: 0;
      ul {
        margin-bottom: 0 !important;
      }
    }
    li {
      display: block;
      a {
        text-align: left;
        // padding: 12px 20px;
        padding: 16px 20px;
        background: url(../img/common/ico_link.png) no-repeat 10px center;
        background-size: 3px 6px;
        font-size: 12px;
        display: block;
        color: #FFF;
        border-bottom: 1px solid #fff;
        &:before {
          height: 0!important;
        }
      }
    }
    .pulldown_nav {
      li {
        display: none;
        a {
          text-indent: 30px;
          padding: 12px 20px;
          border-bottom: 1px solid rgba(255,255,255,.6);
          background-position: 30px center;
          font-size: 0.8em;
        }
      }
    }
  }
  .sp_btn {
    background: $subColor;
    padding: 15px 12px 5px 13px;
    position: fixed;
    top: 0;
    right: 0;
    text-align: center;
    z-index: 1000;
  }
  .HiddenCheckbox {
    display: none;
  }
  .MenuHeader {
    color: #fff;
    display: inline-block;
    float: left;
    font-weight: 100;
    line-height: 30px;
    margin: 0 0 0 15px;
    opacity: 0;
    position: relative;
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
    -webkit-transition: -webkit-transform 0.6s, opacity 0.5s;
    transition: transform 0.6s, opacity 0.5s;
    z-index: 2;
  }
  .MenuIcon {
    cursor: pointer;
    display: block;
    float: left;
    height: 25px;
    position: relative;
    width: 25px;
    z-index: 2;
  }
  .MenuIcon::before {
    -webkit-box-shadow: #fff 0 10px 0;
    box-shadow: #fff 0 8px 0;
    height: 3px;
    -webkit-transform-origin: left top;
    -ms-transform-origin: left top;
    transform-origin: left top;
    width: 25px;
  }
  .MenuIcon::after {
    bottom: 6px;
    height: 3px;
    -webkit-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    transform-origin: left bottom;
    width: 25px;
  }
  .MenuIcon::before,
  .MenuIcon::after {
    background: #fff;
    display: block;
    content: '';
    position: absolute;
    -webkit-transition: -webkit-box-shadow 0.2s linear, -webkit-transform 0.4s 0.2s;
    transition: box-shadow 0.2s linear, transform 0.4s 0.2s;
  }
  .Menu {
    background: #333;
    bottom: 0;
    left: -90%;
    position: absolute;
    top: 0;
    width: 90%;
    -webkit-transition: left 0.4s;
    transition: left 0.4s;
  }
  .HiddenCheckbox:checked ~ .MenuHeader {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .HiddenCheckbox:checked ~ .MenuIcon::before {
    -webkit-box-shadow: transparent 0 0 0;
    box-shadow: transparent 0 0 0;
    -webkit-transform: rotate(45deg) translate3d(4px, -3px, 0);
    transform: rotate(45deg) translate3d(4px, -3px, 0);
  }
  .HiddenCheckbox:checked ~ .MenuIcon::after {
    -webkit-transform: rotate(-45deg) translate3d(2px, 5px, 0);
    transform: rotate(-45deg) translate3d(2px, 5px, 0);
  }
  .HiddenCheckbox:checked ~ .Menu {
    left: 0;
  }
}

//------------# contents #------------
.bg_main {
  width: 100%;
  height: 303px;
  background: url(../img/common/bg_main.jpg);
  margin-bottom: 25px;
  img {
    display: block;
    @include defaultWidth;
  }
  @media (max-width: 767px) {
    margin-top: -50px;
    height: auto;
    overflow: hidden;
    img {
      width: 200%;
    }
  }
}

.breadcrumb {
  background: none;
  padding: 0 0 30px 20px;
  &>li+li:before {
    content: "> ";
  }
  @media (max-width: 767px) {
    display: none;
  }
}

.bnr_area {
  margin-bottom: 60px;
}

.page_top {
  position: fixed;
  bottom: 40px;
  right: 20px;
}

@media (min-width: 768px) {
  .contents {
    padding-top: 185-64px;
  }
  .dumy_cont {
    padding: 0 15px;
  }
  .dumy_cont-2 {
    padding: 0 15*2px;
  }
  .bnr_area {
    a {
      @include textIn;
      @include bgs(306px,186px);
      @include hoverAction;
    }
    .bnr_blog a {
      background-image: url(../img/common/bnr_blog.jpg);
    }
    .bnr_fb a {
      background-image: url(../img/common/bnr_facebook.jpg);
    }
    .bnr_perio a {
      background-image: url(../img/common/bnr_perio.jpg);
    }
  }
}

.bnr_area {
  img {
    width: 100%;
  }
}
.bnr_area_wrap {
  border-top: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
  padding-top: 60px;
  width: 100%;
  .bnr_area {
    width: $baseMinWidth + 10px;
    margin: 0 auto 60px;
  }
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  .bnr_area {
    margin: 0 0 15px;
    img {
      width: 100%;
    }
    li {
      margin: 0 0 10px;
    }
  }
  .bnr_area_wrap {
    padding-top: 20px;
    margin-bottom: 20px;
    .bnr_area {
      width: 100%;
      margin: 0 auto;
    }
  }
}


//------------# footer #------------
.last_contents {
  width: 100% !important;
  .foot_info_box {
    margin-bottom: 20px;
    line-height: 1.4;
    img {
      display: inline-block;
      margin-right: 24px;
    }
    span {
      display: inline-block;;
    }
  }
}

@media (min-width: 768px) {
  .last_contents {
    .l-boxl {
      width: 480px;
    }
    .l-boxr {
      width: 417px;
      .foot_logo {
        margin-bottom: 23px;
      }
      .foot_tel_box a img {
        margin-bottom: 12px;
        display: block;
      }
    }
  }
}

footer {
  text-align: center;
  background: $baseColor;
  .container {
    padding: 30px 0 25px;
  }
  ul li {
    display: inline-block;;
  }
  .foot_first_list {
    margin: 0 0 3px;
    li {
      margin: 0 13px;
      &.link_low {
        color: #999;
        margin: 0 2px;
        span {
          margin: 0 3px 0 9px;
        }
      }
    }
  }
  .foot_second_list {
    li {
      margin: 0 13px;
    }
  }
  .copy {
    background: $subColor;
    color: #fff;
    font-size: 12px;
    padding: 7px 0;
  }
}

@media (max-width: 767px) {
  .last_contents {
    .time_table {
      margin-bottom: 20px;
    }
    .foot_info_box {
      font-size: 12px;
      img {
        margin-right: 10px;
      }
    }
    .foot_tel_box {
      font-size: 12px;
      margin: 0 0 15px;
      img {
        width: 100%;
        display: block;
        margin-bottom: 4px;
      }
    }
  }
  footer {
    ul {
      li {
        display: block;
        a {
          display: block;
          span {
            display: none;
          }
        }
      }
    }
  }
}
