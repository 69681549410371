//modul

//headding
.headding_l {
  font-size: 22px;
  background: url(../img/common/ico_headding.png) no-repeat left center;
  padding: 13px 0 13px 45px;
  margin: 0 0 30px;
  &.line_bb {
    border-bottom: 1px dashed #3b56ac;
    padding: 10px 0 15px 45px;
    background-position: left 3px;
  }
  &.line_bb_solid {
    border-bottom: 2px solid #3b56ac;
    padding: 10px 0 15px 45px;
    background-position: left 3px;
  }
}

.headding_m {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background: #3A4DB1;
  padding: 20px 20px;
  border-radius: 5px;
  box-shadow: 0px 5px 0px #14178F;
  margin: 60px 0 0;
}

.headding_s {
  font-size: 16px;
  color: #0e2b8b;
  background: #D7DDF1 url(../img/common/btn_head_open.png) no-repeat 98% center;
  padding: 20px 60px 20px 20px;
  border-radius: 5px;
  box-shadow: 0px 5px 0px #BEC4D7;
  margin: 60px 0 0;
  &:hover {
    @media (min-width: 768px) {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}

//sp
@media (max-width: 767px) {
  .headding_l {
    font-size: 16px;
    background-size: 18px 21px;
    padding: 10px 0 10px 30px;
    font-weight: bold;
    margin: 0 0 15px;
    &.line_bb {
      padding: 10px 0 10px 30px;
      background-position: center left;
    }
    &.line_bb_solid {
      padding: 10px 0 10px 30px;
      background-position: center left;
    }
  }
  .headding_m {
    margin: 20px 0 0;
  }
  .headding_s {
    margin: 20px 0 0;
    background-position: 95% center;
  }
}


//button
.link_btn {
  a {
    font-weight: bold;
    font-size: 14px;
    color: $baseFontColor;
    padding: 12px 20px 12px 45px;
    border: 5px solid $subBorderColor;
    background: url(../img/common/ico_btn_ar.png) no-repeat 18px 9px;
    @media (min-width: 768px) {
      @include hoverAction;
    }
    &:hover {
      text-decoration: none;
    }
  }
}


//sp
@media (max-width: 767px) {
  .link_btn {
    a {
      display: block;
      background-position: 18px 12px;
      padding: 12px 20px 9px 45px;
    }
  }
}
