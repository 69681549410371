//index

.page_index {
  .keyv_slide {
    .slick-dots {
      text-align: center;
      position: absolute;
      position: absolute;
      bottom: 14px;
      left: 50%;
      margin-left: -50px;
      li {
        display: inline-block;;
      }
      button {
        @include textIn;
        width: 15px;
        height: 15px;
        margin: 0 5px;
        border-radius: 50%;;
        border:0;
        background: #999;
      }
      li.slick-active button {
        background: $subColor;
      }
    }
  }
  .index_menu {
    margin-bottom: 30px;
    a {
      border-radius: 5px;
      background: #3b56ac;
      box-shadow: 0px 4px 0px 0px rgba(14, 43, 139, 1);
      color: #fff;
      text-align: center;
      z-index: 68;
      display: block;
      margin: 0 0 20px;
      font-size: 16px;
      font-weight: bold;
      @include noHover;
      @media (min-width: 768px) {
        @include hoverAction;
      }
      &:hover {
        text-decoration: none;
      }
      img {
        display: inline-block;
        vertical-align: middle;
        margin: 0 7px 0 0;
        padding: 11px 0 9px;
      }
      .txt {
        margin: 4px 0 0;
        display: inline-block;;
        vertical-align: middle;
        width: 100px;
        text-align: center;
      }
    }
  }
  .index_message {
    margin-bottom: 60px;
    .message_lead {
      font-size: 18px;
      font-weight: bold;
    }
    .link_btn {
      margin-top: 20px;
    }
    .l-boxr {
      text-align: center;
      .intro {
        margin-top: 20px;
        font-weight: bold;
        font-size: 16px;
        span {
          font-size: 12px;
          display: block;
        }
      }
    }
  }
  .bnr_area {
    margin-bottom: 60px;
  }
  .index_map {
    margin-bottom: 60px;
    #map {
      width: 100%;
      height: 330px;
    }
  }
}


//pc
@media (min-width: 768px) {
  .slick-slider {
    width: 1133px;
    margin: 0 auto;
  }
  .slider_wrapper {
    box-shadow: 0px 2px 2px 0px rgba(16, 6, 15, 0.1);
  }
  .keyv_slide {
    margin-bottom: 30px;
    box-shadow: 0;
    img {
      margin: 0 auto;
    }
    .slick-prev {
      position: absolute;
      left: -30px;
      top: 50%;
      border: 0;
      margin-top: -18+30px;
      @include textIn;
      background: url(../img/common/ico_ar_prev.png) no-repeat;
      height: 36px;
      width: 23px;
    }
    .slick-next {
      position: absolute;
      top: 50%;
      left: auto;
      border: 0;
      right: -30px;
      margin-top: -18+30px;
      @include textIn;
      background: url(../img/common/ico_ar_next.png) no-repeat;
      height: 36px;
      width: 23px;
    }
  }
  .index_message {
    .message_lead {
      margin-bottom: 40px;
    }
    .l-boxl  {
      width: 610px;
    }
    .l-boxr {
      margin: 5px 0 0;
    }
  }
}

//sp
@media (max-width: 767px) {
  .slick-dots {
    display: none!important;
  }
  .sp_tel {
    padding: 0 5px;
    margin: 0 0 20px;
  }
  .keyv_slide {
    margin-bottom: 25px;
    img {
      width: 100%;
    }
    .slick-next,.slick-prev {
      display: none!important;
    }
  }
  .page_index {
    .index_menu {
      text-align: center;
      li {
        padding: 0 5px;
      }
      a {
        text-align: center;
        font-size: 14px;
        margin: 0 0 13px;
        img {
          margin:0;
        }
        .txt {
          margin-top: -4px;
          display: block;
          width: auto;
        }
      }
    }
    .index_message {
      margin: 0 0 25px;
      font-size: 14px;
      .l-boxr {
        width: 60%;
        margin: 0 auto 10px;
        img {
          width: 100%;
        }
      }
      .message_lead {
        font-size: 15px;
        font-weight: bold;
        margin: 0 0 8px;
      }
    }
    .index_map {
      margin-bottom: 30px;
    }
    .bnr_area {
      margin-bottom: 20px;
    }
  }
}
