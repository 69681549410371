//privacy

.page_privacy {
  .bg_main {
    background: url(../img/common/bg_main_about.jpg);
  }
  .privacy_cont {
    margin-bottom: 60px;
  }
  .privacy_list {
    th {
      background: #D8F3F8;
      padding: 0 3% 0 3%;
      font-weight: bold;
      text-align: left;
      width: 30%;
      border-left: none !important;
      border-right: none !important;
    }
    td {
      padding: 30px 0 30px 3%;
      text-align: left;
      border-left: none !important;
      border-right: none !important;
    }
  }
}


//pc
@media (min-width: 768px) {
  .privacy_cont {
  }
}

//sp
@media (max-width: 767px) {
  .sp_tel {
    padding: 0 5px;
    margin: 0 0 20px;
  }
  .page_privacy {
    .privacy_cont {
      margin: 0 0 25px;
      font-size: 14px;
    }
    .bnr_area {
      margin-bottom: 20px;
    }
    table th, table td {
      padding: 10px !important;
      display: block;
      width: 100% !important;
      border: none;
      border-radius: 6px;
    }
  }
}
