//equipment

.page_equipment {
  .bg_main {
    background: url(../img/common/bg_main_about.jpg);
  }
  .equipment_cont {
    margin-bottom: 60px;
    .message_lead {
      font-weight: bold;
      text-align: center;
      margin-top: -20px;
      font-size: 18px;
    }
    .l-boxl,.l-boxr {
      width: 460px;
      p {
        width: 94%;
        margin: auto;
        &.txtblue {
          margin: 20px 0 10px;
        }
      }
    }
    .img_equipment_counter {
      margin: 0 0 70px;
    }
    .txtblue {
      margin-top: 60px;
      color: #0e2b8b;
      font-size: 16px;
      text-align: center;
    }
    .cont_s {
      &:last-child {
        // padding-bottom: 0;
        border-bottom: none;
      }
      .center {
        text-align: center;
      }
    }
  }
}

//pc
@media (min-width: 768px) {
  .equipment_cont {
    .message_lead {
      margin-bottom: 40px;
      &.message_lead_n {
        margin-bottom: 20px;
      }
    }
  }
}

//sp
@media (max-width: 767px) {
  .sp_tel {
    padding: 0 5px;
    margin: 0 0 20px;
  }
  .page_equipment {
    .equipment_cont {
      margin: 0 0 25px;
      font-size: 14px;
      .img_equipment_counter {
        margin: 20px 0 30px;
        img {
          width: 100%;
          margin: 10px auto;
          border-radius: 6px;
        }
      }
      .message_lead {
        text-align: left;
        margin: -20px auto 10px !important;
        font-size: 16px;
      }
      .l-boxl {
        float: none;
        width: 100% !important;
      }
      .l-boxr {
        float: none;
        width: 100% !important;
        padding-top: 20px;
      }
      .cont_s {
        border-bottom: none;
        p {
          width: 100%;
          margin: auto !important;
          &.img_equipment_counter {
            border-bottom: 1px dashed gray;
            margin-bottom: 10px !important;
          }
        }
      }
      .l-boxl,.l-boxr {
        img {
          display: block;
          width: 100%;
          margin: 0 auto 20px;
          border-radius: 6px;
        }
        &.txtblue {
          width: 94%;
          margin-top: 30px;
          margin-bottom: 10px !important;
        }
      }
    }
  }
}
