
//------------# var #------------

// color
$bgColor: #fff;
$baseColor: #DDF2F7;
$subColor: #093189;
$accentColor: #ff3366;


// font
$baseFontFamily: 'Hiragino Kaku Gothic ProN', Meiryo, sans-serif;
$baseLineHeight: 1.6;
$baseFontSize: 75%;
$strongText: 120%;


// font-size
$size10: 87%;
$size11: 92%;
$size12: 100%;
$size13: 107%;
$size14: 117%;
$size15: 125%;
$size16: 133%;
$size17: 142%;
$size18: 150%;
$size20: 167%;
$size22: 183%;
$size24: 200%;
$size26: 217%;
$size27: 225%;
$size28: 233%;
$size32: 267%;


//font color
$baseFontColor: #333;
$subFontColor: #C72422;
$accentFontColor: #fff;
$grayFontColor: #ccc;
$captionFontColor: red;


// headding
$headdingColor1: #FF0000;
$headdingColor2: #444;
$headdingColor3: #000;
$headdingColor4: #000;
$headdingColor5: #000;
$headdingColor6: #000;


// box
$baseMinWidth: 960px;
$baseBorderStyle: solid;
$baseBorderWidth: 1px;
$baseBorderColor: #aaa;
$subBorderColor: #e5ebf5;
$grayBorderColor: #c6c6c6;
$tableBorder: #95cadb;
$tablePadding: 10px;
$tabeleBg : $baseColor;
$baseBorder: $baseBorderStyle $baseBorderColor $baseBorderWidth;


//link
$baseLinkColor: #0e2b8b;
$baseLinkFontWeight: bold;
$baseHoverColor: #9bbc00;
$baseLinkDecoration: none;
$baseHoverDecoration: underline;

//margin
$baseMargin: 20px;



@mixin opacity($transparent) {
	opacity:$transparent;
	filter: alpha(opacity= $transparent * 100);
}



//------------# mixin #------------

@mixin defaultWidth {
	width: $baseMinWidth;
	margin: 0 auto;
}

@mixin noHover {
	&:hover {
		img {
			@include opacity(1);
		}
	}
}

@mixin textIn {
	text-indent: -9999px;
	display: block;
}

@mixin noPadding {
	letter-spacing: -0.4em;
	li {
		letter-spacing:normal;
	}
}

@mixin hoverAction($opacity:0.7,$time:0.15s) {
	&:hover {
		-webkit-transition: $time opacity linear;
		   -moz-transition: $time opacity linear;
		    -ms-transition: $time opacity linear;
		     -o-transition: $time opacity linear;
		        transition: $time opacity linear;
		@include opacity($opacity);
	}
}

@mixin hrList($marginTop,$marginRight,$marginBottom,$marginLeft) {
	margin: 0 0 0 (-$marginLeft);
	overflow: hidden;
	&>li {
		float: left;
		margin: $marginTop $marginRight $marginBottom $marginLeft;
	}
}

@mixin bgs($width,$height) {
	width: $width;
	height: $height;
	-webkit-background-size: $width $height;
	     -o-background-size: $width $height;
	        background-size: $width $height;
}

@mixin glidList($column,$margin) {
	overflow: hidden;
	&>li {
		widows: $baseMinWidth / $column - $margin;
		float: left;
		margin: 0 0 $margin $margin;
	}
}

@mixin borderLink ($linkColor,$borderHeight,$left,$bottom,$time) {
	position: relative;
	&:after {
		content: "";
		height: $borderHeight;
		position: absolute;
		background: $linkColor;
		left: $left;
		bottom: $bottom;
		width: 0;
		-webkit-transition: width $time ease-in-out;
			-moz-transition: width $time ease-in-out;
				-ms-transition: width $time ease-in-out;
				-o-transition: width $time ease-in-out;
						transition: width $time ease-in-out;
	}
	&:hover:after {
		width: 100%;
	}
}

@mixin ghostbutton($font-size, $font-color, $border-size, $border-color, $padding, $transition-speed, $hover-color) {
	 display:inline-block;
	 text-decoration:none;
	 text-transform:uppercase;
	 font-size: $font-size;
	 color:$font-color;
	 border:$border-size solid $border-color;
	 padding:$padding;
	 -webkit-transition: color $transition-speed, background $transition-speed;
	 transition: color $transition-speed, background $transition-speed;
		&:hover {
		 background:$border-color;
		 color:$hover-color;
	 }
 }
